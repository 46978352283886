
import { Component, Vue } from 'vue-property-decorator';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import { RawLocation } from 'vue-router';
import { RouteNames } from '@/constants';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import { RadioField, TextField } from '@/components/form/form.types';
import { RpaOpsModule } from '@/store/om27.module';
import {
  LogicalBot,
  OpenTicketRequest,
  TicketType,
  UserPreferences,
} from '@/types/om27.types';
import DataGrid from '@/components/DataGrid.vue';
import { Promised } from 'vue-promised';
import { ValidationObserver } from 'vee-validate';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import { openTicket } from '@/api/om27.api';
import { openSuccessSnackbar } from '@/utils/components';
import invariant from 'ts-invariant';

type FormModel = [RadioField<'type'>, TextField<'ticket-number'>, TextField<'details'>];
@Component({
  components: {
    Grid,
    GridCell,
    FullScreenForm,
    GButton,
    ValidatedForm,
    DataGrid,
    Promised,
    ValidationObserver,
    GDialog,
  },
})
export default class SignOff extends Vue {
  closeRoute: RawLocation = {
    name: RouteNames.Om27,
  };

  get timezone(): UserPreferences['preferred_timezone'] {
    return RpaOpsModule.userPreferences.preferred_timezone;
  }
  get logicalBotId(): number {
    return parseInt(this.$route.params.logicalBotId, 10);
  }

  get botId(): number {
    return parseInt(this.$route.params.botId, 10);
  }

  get logicalBot(): LogicalBot | undefined {
    return RpaOpsModule.logicalBots.find(bot => bot.logicalBotId === this.logicalBotId);
  }

  get bots(): LogicalBot[] {
    if (this.logicalBot) {
      const lb = {
        ...this.logicalBot,
        subRecords: this.logicalBot.subRecords.filter(bot => bot.botId === this.botId),
      };
      return [lb];
    }

    return [];
  }

  get assignmentGroup() {
    return this.logicalBot?.assignmentGroup;
  }

  get selectedBots(): number[] {
    return this.bots.map(bot => bot.logicalBotId);
  }

  get botSelectionIsValid(): boolean {
    return !!this.logicalBot?.subRecords.find(rec => rec.botId === this.botId);
  }

  get ticketType(): TicketType {
    const type = this.$route.params.type;
    try {
      invariant(
        type === 'blocking' || type === 'nonblocking' || type === 'intermittent',
        'Invalid ticket type',
      );
      return type;
    } catch (e) {
      this.$logger.warn('Invalid ticket type', type, 'defaulting to Blocking Ticket');
      return 'blocking';
    }
  }

  get openingTicketActionType() {
    return this.formModel.find((formItem) => formItem.key === 'type')?.value;
  }

  get title(): string {
    const type = this.ticketType;

    return {
      blocking: 'Blocking Ticket',
      nonblocking: 'Enhancement/ Break Fix Ticket',
      intermittent: 'System/Intermittent Ticket',
    }[type];
  }

  get description(): string {
    const type = this.ticketType;

    return {
      blocking:
        'This ticket prevents the job from running, once it is resolved, it will indicate the bot was successful. Incident tickets will be completed through Service Now.',
      nonblocking:
        'A convenience method to keep track of open issues, break fixes, or enhancements.',
      intermittent:
        'Indicates that the bot can not run typically due to a global problem, or to indicate chronic problems that sometimes cause the bot to fail.',
    }[type];
  }

  formModel: FormModel = [
    {
      type: 'radio',
      label: 'Provide ticket details',
      key: 'type',
      value: 'new',
      inline: false,
      options: [
        {
          value: 'new',
          label: 'Open a new ticket',
        },
        {
          value: 'attach',
          label: 'Attach an existing ticket',
        },
      ],
      validation: {
        rules: 'required',
      },
    },
    {
      type: 'text',
      label: 'Provide ticket details',
      key: 'ticket-number',
      value: '',
      attrs: {
        placeholder: 'Enter incident ticket number',
      },
      validation: {
        rules: 'required',
      },
      hideIf: {
        fieldKey: 'type',
        fieldValue: 'new',
      },
    },
    {
      type: 'long-text',
      label: 'Provide ticket details',
      key: 'details',
      value: '',
      attrs: {
        placeholder: 'Please provide reasoning for opening a blocking ticket',
      },
      validation: {
        rules: 'required',
      },
    },
  ];

  get formData(): OpenTicketRequest {
    const isAttach = this.formModel[0].value === 'attach';
    const details = this.formModel[2].value;
    const data: OpenTicketRequest = {
      details,
      botId: this.botId,
      logicalBotId: this.logicalBotId,
    };
    if (isAttach) {
      data.attachExistingTicketNumber = this.formModel[1].value;
    }
    return data;
  }

  goToDashboard(): void {
    this.$router
      .replace({
        name: RouteNames.Om27,
        query: this.$route.query || {
          expand: this.logicalBotId.toString(),
        },
      })
      .catch(() => void 0);
  }

  submitPromise: Promise<unknown> = Promise.resolve();
  submitFormData(): void {
    this.submitPromise = openTicket(this.ticketType, this.formData);
    this.submitPromise.then(() => {
      openSuccessSnackbar.call(this, 'You have successfully created a ticket');
      this.goToDashboard();
    });
  }

  promise: Promise<unknown> | null = null;
  async created(): Promise<void> {
    if (!RpaOpsModule.logicalBots.length) {
      this.promise = RpaOpsModule.getDashboard();
    } else {
      this.promise = Promise.resolve();
    }

    await this.promise;
    if (!this.botSelectionIsValid) {
      this.goToDashboard();
    }
  }
}
